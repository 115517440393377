@use './assets/styles/breakpoints' as *;
@use './assets/styles/colors' as *;




section.big.experience {
	
	&.experience {
		background-color: $col-dark;
	}
	
	.sectionTitle {
		text-transform: uppercase;
		opacity: 0.4;
		letter-spacing: 0.05rem;
		font-size: 1.9rem;
		margin-bottom: 0.2rem;
	}

	.project {
		position: relative;
		display:grid;
		// Note: Text column goes static at doc width 360px (the "s" breakpoint) 
		// but this grid goes single column at 285px. It's because there are margins + padding.
		// Not a great setup.
		grid-template-columns: repeat(auto-fit, minmax(285px, auto));
		gap: 1rem;
		margin-bottom: 8rem;
		margin-inline: 2rem;


		.text-column {
			position: sticky;
			@include bp(s-down) {
				position: static;
			}
			align-self: flex-start;
			// The nav height
			top: calc(0.7rem + 1.1rem + 0.7rem);
			width: 100%;
            height:auto;
			margin-inline: 0;
			padding-inline: 0;
		}
		.smallcaps {
			color: $col-bright-accent;
			margin-bottom:0 !important;
		}
		h3 {
			margin:0;
		}
		h2 {
			margin:0;
			margin-bottom: 0.4rem;
			font-weight: 700;

			.hash {
				visibility:hidden;
				color: $col-light-faint;
				opacity: 50%;
			}
			&:hover .hash {
				visibility:visible;
			}
		}


		.badges {
			display:flex;
			gap: 0.6rem;

			img {
				height: 3.5rem;
			}
		}


		.summary {
			margin-top: 0.2rem;
			font-weight: bold;
			line-height: 1.4rem;
			font-size: 1.2rem;
		}
		.description {
			&, p, ul {
				line-height: 1.5rem;
			}
			ul {
				margin:0;
				padding-block:0.2rem;
				// Placement of the bullet char is off,
				// and different size on different browsers.
				// Use none.
				list-style: none;
				// list-style-type: "●";

				> li:before {
					content: "●";
					font-size: 22px;
					position:absolute;
					left: -5px;
					margin-top:-1.5px;
				}
				
				> li {
					margin-block: 0.4rem;
					margin-left: -0.6rem;
					// padding-inline-start: 0rem;
					padding-inline-start: 0.4rem;
				}

				// Turn off big circle bullets for mobile, it looks rubbish.
				@include bp(s-down) {
					&:not(.awards) {
						list-style: disc;	
					
						> li:before {
							display:none;
						}
						> li {
							margin-left:0rem;
							padding-left:0px;
						}
					}
				}

				&.awards {
					list-style: none;
					position:relative;
				}
				&.awards > li:before {
					display:block;
					// content: "★";
					content: "";
					background-image: url("../../assets/icons/medal.svg");
					width: 1.4rem; height:1.4rem;
					background-blend-mode: screen;
					background-size:contain;
					background-repeat:no-repeat;
					filter: invert(100%)  brightness(93%);
					font-size: 22px;
					position:absolute;
					left: -8px;
					margin-top:2px;
				}
			}

		}




		.roles, .tech {
			text-transform: uppercase;
		}
		.roles, .links, .tech {
			h3 {
				margin-block: 0.3rem;
			}
			p {
				margin-block: 0;
			}
			margin-bottom: 1rem;
		}

		.images {

			min-width: 300px;
			max-width: 500px;

			@include bp(s-down) {
				max-width: 100%;
			}
			img {
				max-width: 100%;
				object-fit: cover;
			}
			> * {
				margin-bottom: 0.5rem;
			}
		}
	}
}