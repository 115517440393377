
@use "sass:math";

@mixin recolor($color: #000) {
  $r: math.div(red($color), 255);
  $g: math.div(green($color), 255);
  $b: math.div(blue($color), 255);
  $a: alpha($color);
 
  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($a);

  // color filter
  $svg-filter-id: "recolor";
  filter: url('data:image/svg+xml;utf8,\
  <svg xmlns="http://www.w3.org/2000/svg">\
    <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
      <feColorMatrix type="matrix" values="\
       0 0 0 0 #{$r}\
       0 0 0 0 #{$g}\
       0 0 0 0 #{$b}\
       0 0 0 #{$a} 0\
      "/>\
    </filter>\
  </svg>\
  ##{$svg-filter-id}');
}


:root {
	--tip-bg-color: #ff617b;
}
.tooltip-wrapper {
	position:relative;
}

.tooltip {
	position:absolute;
	padding: 0.5rem;
	background-color: var(--tip-bg-color);
	color:white;
	border-radius: 0.5rem;
	z-index: 100;

	visibility: hidden;
	opacity:0;
	translate: 0px 20px;
}
.tooltip.on {
	transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	visibility:visible;
	opacity:1;
	translate: 0px 0px;
}



.tooltip .bubble {
	white-space: no-wrap;
}


.tooltip::before {
	content: "";
	background-image: url("../../assets/misc/triangle-down.svg");
	background-repeat: no-repeat;
	background-size:contain;
	width: 10px; height:10px;
	position:absolute;
	left:50%;
	transform: translate(-50%);
	@include recolor(#ff617b);
}

.tooltip.top-middle::before {
	top: calc(100% - 1px);
}

.tooltip.bottom-middle::before {
	top:calc(-10px + 1px);
	transform: translate(-50%) rotate(180deg);
}
