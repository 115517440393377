@use '../../assets/styles/_colors.scss' as *;
@use '../../assets/styles/breakpoints' as *;
.nav {
	position: fixed;
	right: 0;
	top: 0;
	// max-height: 2rem;
    padding-inline: 1rem;
    padding-block: 0.3rem;
	z-index: 10;
	border-radius: 0 0 0 0.6rem;
	// background-image: linear-gradient(to bottom, hsla(0,100%,100%,100%) 50%, hsla(0, 100%, 100%, 0%) 100%);
	// background-image: linear-gradient(to bottom, hsla(60, 23%, 97%, 30%) 70%, hsla(60, 23%, 97%, 0%) 100%);
	// width: 100%;
	@include bp(s-down) {
		padding-block: 0.4rem;

	}
	// @include bp(s-down) {
		background-color: hsla(0,100%,100%,1);
		box-shadow: 0px 0px 15px 0px hsla(0,0%,0%, 2.5%);
		// background-image: linear-gradient(to bottom, hsla(60, 23%, 97%, 70%) 70%, hsla(60, 23%, 97%, 0%) 100%);
	// }
}


  
  .nav.show{
	visibility: visible;
	transition: all 0.3s;
  }
  .nav.hide {
	visibility: hidden;
	transition: all 0.3s;
	transform: translateY(-100%);
  }


.nav ul {
	justify-content: flex-end;
	gap: 0.3rem;
	position:relative;
}

.nav a {
	// border: 1px solid pink;
	font-weight:normal;
	text-transform: uppercase;
	color: #11111188;
	// background-image: linear-gradient(to bottom,hsla(60, 23%, 97%, 0%) 0%,  hsla(60, 23%, 97%, 70%) 30%,  hsla(60, 23%, 97%, 70%) 70%, hsla(60, 91%, 53%, 0) 100%);
	// background-color: hsla(60, 23%, 97%, 70%);
	// backdrop-filter: blur(2px)  drop-shadow(8px 8px 10px green);
	// -webkit-text-stroke: 0.3px white;
	text-shadow:
     0px  0px 0px hsla(0,100%,100%,0.2),
    -0px  0px 0px hsla(0,100%,100%,0.2),
    -0px -0px 0 hsla(0,100%,100%,0.2),
	0px -0px 0 hsla(0,100%,100%,0.2),
	0px  0px 0px hsla(0,100%,100%,0.2),
    -0px  0px 0px hsla(0,100%,100%,0.2),
    -0px -0px 0 hsla(0,100%,100%,0.2),
	0px -0px 0 hsla(0,100%,100%,0.2);
	padding-inline: 0.3rem;
	padding-block: 0.1rem;
}
.nav a:hover {
	transition: all 0.2s ease-out;
	text-decoration: none;
	color: $col-text;
	background-color: hsl(0, 0%, 100%, 0.9);
	// background-color: hsl(0, 0%, 89%, 1);
	border-radius: 0.2rem;
	// filter:invert(100%);
}

.nav  button.email:hover .icon  {
	filter: contrast(100%);
}
.nav .email .icon {
	// border: 1px solid green;
	height:1.35rem;
	filter:contrast(0%);
	position:relative;
	top:-1.5px;
}