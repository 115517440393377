@use './assets/styles/_layout.scss' as *;
@use './assets/styles/_colors.scss' as *;
@use './assets/styles/breakpoints' as *;
@use './assets/styles/vars' as *;

.app {
    // text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .app-content{
        z-index:0;
    }
}

.app section.home {
	display:flex;
	flex-direction: column;
	// min-height: 100dvh;
	min-height: 100dvb;

	overflow: hidden;


	header {
		display:grid;
		grid-template-areas:
			"pic subtitle"
			"pic title"
		;
		padding-bottom: 0.7rem;


		justify-content: center;

		.profilePic {
			grid-area: pic;
			margin-inline-end: 1.5rem;
			img {
				width:4.7rem;
				height: 4.7rem;
				border-radius: 50%;
				box-shadow: 0 0 10px 5px hsla(0,0%,0%,8%);
				filter: opacity(80%);
			}
			&.bubble {
				// font-weight: 700;
			}
		}
		h1 {
			grid-area: title;
			font-size: 2.7rem;
			margin: 0rem;
			text-align: center;
			white-space: nowrap;
		}


		
		h2 {
			grid-area: subtitle;
			font-size: 1.5rem;
			margin: 0rem;
			text-align: center;
			white-space: nowrap;
		}
		// h2.span {}
		h2 .name {
			color: $col-dark-deep;
			text-transform: uppercase;
		}
		h2 .location {
			margin-inline-start: 1rem;
			// color: $col-dark-spirit;
			color: #919191;
			text-transform: uppercase;
		}


		@include bp(s-down) {
			grid-template-areas:
				"subtitle"
				"title"
			;
			.profilePic {
				display:none;
			}
			h1 {
				font-size: 2.5rem;
			}
		}
	}



	section.tools {
		display: flex;
		gap: 0.8rem;
	
		padding-bottom: 2rem;
		vertical-align:baseline;

		button {
			display:flex;
			align-items: center;
			// outline: 1px solid green;
			font-size: 1.2rem;
			color: $col-dark-ish;
		}
		button:hover {
			color: $col-dark;
			.icon {
				filter: contrast(100%) !important;
			}
		}
		.icon {
			// outline: 1px solid red;
		}
		button.contact {
			.icon {
				filter: contrast(0%) brightness(60%);
				height: 1.3rem;
				margin-inline-end: 0.15rem;
				position:relative;
				top: -1.5px;
			}
		}
		button.cv {
			.icon {
				filter: contrast(0%) brightness(60%);
				height: 19px;
				margin-inline-end: 0.3rem;
				position:relative;
				// top: 1px;
				object-fit: contain;
				object-position: center center;
			}
	
		}
	}
	








	.subsection {
		position:relative;
		box-shadow: 0 0 10px 5px rgba(0,0,0,2%);
		border-radius: 2rem;
		margin-bottom: 2rem;
		opacity:0; top: 200px;
		overflow: hidden;

	}
	.subsection.experience {
		animation: 
			home-subsection-start-fast 0.34s cubic-bezier(0.075, 0.82, 0.165, 1.2) 0.0s forwards,
			home-subsection-start-slow 3.34s cubic-bezier(0.075, 0.82, 0.165, 1.2) 0.0s forwards
		;
	}
	.subsection.skills {
		animation: 
			home-subsection-start-fast 0.34s cubic-bezier(0.075, 0.82, 0.165, 1.2) 0.1s forwards,
			home-subsection-start-slow 3.34s cubic-bezier(0.075, 0.82, 0.165, 1.2) 0.1s forwards
		;

	}
	
	.subsection-top {

		h3.subsection-title {
			margin-block: 0.5rem;
			a {
				font-size: 1.7rem;
				// position:relative;
				// top:1.5px;
			}
			img {
				height: 1em;
				vertical-align: middle;
				margin-inline-end:0.4rem;
				position:relative;
			}
		}
		&.experience .subsection-title img {
			top: -2.5px;
		}
		&.skills .subsection-title img {
			top: -3px;
		}


		background-color: $col-light;
		padding: 1rem;

		p {
			margin-top:0.5rem;
		}
		p:last-of-type {
			margin-bottom: 0.2rem;
		}



	details {
	
		&[open] .content {
			animation: sweepIn .5s ease-out forwards;
		}

		button {
			background-color: $col-bg-grey;
			border-radius: 0.3rem;
			font-size:0.9rem;
		}

		> summary {
			list-style: none;
		}

		> summary::marker,
		> summary::-webkit-details-marker {
			font-size:0px;
			max-height: 0px;
			display:none;
		}

		.read-more, .read-less {
			float:right;
			margin-inline-end: 1rem;
			font-size:inherit;
			background:none;
			color: inherit;
		}
		.read-more:hover, .read-less:hover {
			color:$col-darkest;
		}
		.read-less {
			display:none
		}
		&[open] {
			.read-more {
				display:none;
			}
			.read-less {
				display:inherit;
			}
		}

	}


	}

	.subsection-bottom {
		display: flex;
		width:100%;
		padding: 0.1rem 1rem 1rem 1.5rem;
		background-color: hsl(0, 0%, 94%);
		border-radius: 0 0 2rem 2rem;
 		border-bottom: 1px solid rgba(0, 0, 0, 4%);

		

		.link {
			display: inline-flex;
			text-align: middle;
			margin-top: 0.7rem;
			text-decoration: none;
			color: #444;
			.arrow {
				align-self: center;
				margin-inline-start: 0.1rem;
				width: 1rem; 
				height: 1rem;
				margin-top:0.14rem;
			}
		}
		.link:hover {
			filter: brightness(0%) ;
			
		}
	}


	
	@include bp(s-down) {
		padding-inline: 1rem;

		padding-top: 4rem;


		.text-column {
			padding-inline: 1rem;
		}

		.text-column p {
			font-size: 1.3rem;
			line-height: 1.8rem;
		}

		h1 {
			font-size: 2.4rem;
			margin-top: 0.4rem;
		}
		h2 {
			font-size: 1.4rem;
		}
		h4.subsection-title {
			a {
				font-size: 2rem;
			}
			img {
				font-size: 1.4em;

			}
		}

		.subsection.experience .subsection-title img {
			top: -2.5px;
		}
		.subsection.skills .subsection-title img {
			top: -5px;
		}
	
		.subsection {

			 .link {
				font-size: 1.3rem;
				.arrow {
					margin-top:0.12rem;
				}
			 }
		}

	}



}





@keyframes sweepIn {
	0%    {opacity: 0; margin-top: -10px}
	100%  {opacity: 1; margin-top: 0px}
}



section.big {
	
	padding: 0;
	padding-top: 3.6rem;
		
	@include bp(s-down) {
		padding-top: 1.3rem;
	}


	.sectionTitle {
		font-size: 2.4rem;
		margin-top:1.8rem;
		margin-bottom:0rem;
		text-align: center;
		opacity: 70%;
	}
	.sectionSubtitle {
		font-size: 2.3rem;
		color: $col-eggshell;
		margin-top:-0.4rem;
		margin-bottom:3rem;
		text-align: center;
	}

}



@keyframes home-subsection-on-start {
	from {
		opacity:0%;
		top:200px;
	}
	to {
		opacity:100%;
		top:0px;
	}
}



@keyframes home-subsection-start-slow {
	from {
		opacity:0%;
	}
	to {
		opacity:100%;
	}
}

@keyframes home-subsection-start-fast {
	from {
		top:200px;
	}
	to {
		top:0px;
	}
}



span.icon {
	position:relative;
	
	> span {
		// display:inline-block;
		position:absolute;
		top: -0.13rem;
		margin-inline: 0.1rem;
	}
	&.icon-sweat > span {
		// outline: 1px solid green;
		background-image: url("./assets/icons/icon-sweat.svg");
		background-repeat: no-repeat;
		background-size:contain;
		background-position:top;
		width: 31px;
		height: 31px;
	}
}