$xs-upper-boundary: 360px;
$s-upper-boundary: 640px;
$m-upper-boundary: 1024px;
$l-upper-boundary: 1280px;
$xl-upper-boundary: 1400px;

/**
* Based on code from this guy:
* https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
* Usage:
* my-style{
*    width:100px;
*    @include bp(s){
*        width:50px;
*    }
*}
**/

@mixin bp($range) {
    @if ($range == xs) {
        @media (max-width: #{$xs-upper-boundary - 1}) { @content; }
    } 
    @else if $range == s-down {
        @media (max-width: #{$s-upper-boundary - 1}) { @content; }
    } 
    @else if $range == s {
        @media (min-width: #{$xs-upper-boundary}) and (max-width: #{$s-upper-boundary - 1}) { @content; }
    } 
    @else if $range == m-down {
        @media (max-width: #{$m-upper-boundary - 1}) { @content; }
    } 
    @else if $range == m {
        @media (min-width: #{$s-upper-boundary}) and (max-width: #{$m-upper-boundary - 1}) { @content; }
    } 
    @else if $range == l {
        @media (min-width: #{$m-upper-boundary}) and (max-width: #{$l-upper-boundary - 1}) { @content; }
    } 
    @else if $range == xl {
        @media (min-width: #{$l-upper-boundary}) and (max-width: #{$xl-upper-boundary - 1}) { @content; }
    }
    
    @else if $range == s-up {
      @media (min-width: $s-upper-boundary) { @content; }
    } 
    @else if $range == m-up {
      @media (min-width: $m-portrait-upper-boundary) { @content; }
    } 
    @else if $range == l-up {
      @media (min-width: $l-upper-boundary) { @content; }
    } 
    @else if $range == xl-up {
      @media (min-width: $xl-upper-boundary) { @content; }
    }
  }