@use './assets/styles/_layout.scss' as *;
@use './assets/styles/_colors.scss' as *;
@use './assets/styles/breakpoints' as *;
@use './assets/styles/vars' as *;


.modal.contact .content {
	container: content-query / inline-size;

	overflow-x:hidden;

	width:  clamp(300px, calc(100cqw - 4rem), 600px);
	// height:  clamp(300px, calc(100cqh - 4rem), 800px);
	
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 5rem;

		gap: 1rem;

		.column1 {
			position:relative;
			h1 {
				&.init {
					visibility: hidden;
					top: 20px;
					opacity:0;
				}
				transition: all 0.24s cubic-bezier(0.075, 0.82, 0.165, 1.2) 0.3s;

				position:absolute;
				top: -35px;
				left: 90px;
				z-index: 2;
				white-space: nowrap;
				font-size: 1rem;
				margin:0;
				padding: 0.7rem;
				padding-inline: 2rem;
				border-radius: 1rem 1rem 1rem 0rem;
				color: white;
				background-color: #fb6cbd;
				opacity:1;
			}
			img {
				width:140px;
				height: 140px;
				border-radius: 50%;
				// box-shadow: 0 0 10px 5px hsla(0,0%,0%,8%);
				filter: saturate(70%) brightness(120%);
			}
		}
		
		.column2 {
			padding-inline-end: 1rem;
			h3 {
				text-transform: uppercase;
				margin:0;
				margin-top: 1.5rem;
				font-size: 1.2rem;
				color: $col-dark-faint;
			}
			h4 {
				margin:0;
				font-size: 1.8rem;
			}

			p {
				display: grid;
				column-gap: 0.8rem;
				row-gap: 0.2rem;
				grid-template-columns: auto auto;
				.email {
					white-space: pre;
					button.copy {
						position:absolute;
						margin-top: -2px;
						margin-inline-start: 0.3rem;
						opacity: 70%;
						&:hover {
							opacity: 100%;
						}
						.icon  {
							vertical-align: middle;
							width: 1.5rem;
						}
					}
				}
			}
		}

		// @include bp(s-down) {
		// 	padding-top: 4rem;
		// 	justify-content: center;
		// 	.pic {
		// 		// display: none;
		// 		h1 {
		// 			top: -25px;
		// 			left: -100px;
		// 			border-radius: 1rem;
		// 			border-end-end-radius:0rem;
		// 		}
		// 	}
		// 	h3 {
		// 		margin-top: 1rem;
		// 	}
		// }
	

}

@container content-query (width < 400px) {
	.modal.contact .content .pic h1 {
		top: -30px;
		left: unset;
		right: 70px;
		border-radius: 1rem 1rem 0rem 1rem;
	}

}
