@use './assets/styles/colors' as *;

footer {
	display:flex;

	background-color: $col-bg-grey;

	padding-inline: 3rem;
	padding-block: 2rem;

	.copyright {
		text-transform: uppercase;
	}
	.credits {
		margin-left: auto;
		button {
			text-transform: uppercase !important;
		}
	}

}


.modal.credits .content {
	max-width: min(800px, calc(100dvw - 4rem));
	max-height: calc(100dvh - 4rem);
	
	.love {
		color: hsl(335, 100%, 80%)
	}

	h2 {
		margin:0;
		text-align: center;
	}

	ul {
		margin:0;
		font-size: 0.8rem;
		line-height: 1.5rem;
		column-count: 2;
		gap: 2rem;
	}

}


