@use './assets/styles/colors' as *;



.modal {
    visibility: hidden;
	position:relative;
}
.modal.open {
    visibility: visible;
}

.modal-inner {
	position:fixed;
	z-index:99;
	left:0;
	top:0;
	width: 100dvw;
	height: 100dvh;
	display:grid;
	justify-content: center;
	align-content: center;
	// border: 5px solid yellow;
}
.modal.open .modal-inner {
	transition: background-color 0.5s ease-out;
	background-color: hsla(0,0%,0%, 50%);
}
.modal-inner > .frame {
	background-color: white;
	color: $col-dark-deep;
	border-radius: 1rem;
	border: 0;
	margin: 1rem;
	box-shadow: 0 0 30px 5px hsla(0,0%,0%,10%);
	// min-width: 300px;
	// min-height: 200px;

	position:relative;
	top:30px;
	opacity:0%;
    transition: top .2s ease-out, opacity 0.5s ease-out;
	
	
}
.modal-inner > .frame > .content {
	overflow: scroll;
	height: 100%;
	padding: 2rem;
}

.modal.open .modal-inner > .frame {
	top:0px;
	opacity:100%;
}

.modal button.modal-close {
	position: absolute;
	display:inline-block;
	width: 26px; 
	height: 26px;
	top:0.8rem;
	right:0.8rem;
	border-radius: 1rem;
	border: 0;
	background-color: $col-dark-ish;
	color: $col-light;
	cursor: pointer;
	z-index: 1;
}
.modal button.modal-close:hover {
	background-color: $col-dark-deep;
}
.modal button.modal-close .label {
	position: relative;
	display:inline-block;
	filter: invert(100%);
	width: 80%;
	top: 3px;

}

.prevent-scrolling {
	height: 100%;
	overflow: hidden;
}