// -----------------------
// FONT FAMILIES
$font-body: "Red Hat Mono", Helvetica, Arial, sans-serif;
$font-header: Mulish, Helvetica, Arial, sans-serif;
$font-button: $font-body;
$font-italic: $font-body;
$font-header-italic: $font-header;
$font-body-italic: $font-body;
$font-snack: $font-header;


// -----------------------
// FONT SIZE
$font-size-body-xxl:2.2rem;
$font-size-body-xl:1.5rem;
$font-size-body-l:1.3rem;
$font-size-body:1.2rem;
$font-size-body-small:1.05rem;
$font-size-body-xs:0.9rem;
$font-size-body-xxs:0.75rem;
$font-size-body-smallcaps:0.9rem;
$font-size-body-xsmallcaps:0.75rem;




$max-readable-width: 800px;

