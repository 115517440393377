
@use './assets/styles/colors' as *;
@use './assets/styles/breakpoints' as *;



section.big.skills {
	
	margin-inline: 2rem;
	// display: grid;
	// justify-content: center;
	width: calc(100% - 4rem);


	.sectionTitle {
		opacity: 100%;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 3rem;
	}

	.section-column {
		margin-inline: auto;
		display: grid;
		max-width: 800px;
	}
	.group {
		justify-self: center;
		display: grid;
		width:100%;

		grid-template-columns: 40% 60%;
		// grid-template-columns: repeat(auto-fit, minmax(260px, auto));
		margin-bottom: 2rem;

		background-color: white;
		border-radius: 1rem;
		padding-inline: 1rem;
		padding-block: 1rem;

		box-shadow: 0 0 20px 10px hsla(0,0%,0%,1.5%);

		.header {
			// border: 1px solid pink;
			text-align: center;
			padding-inline-end: 0.6rem;
			img {
				width: 6rem;
			}
			h3 {
				margin-top: 0.5rem;
				text-transform: uppercase;
				font-size: 1.1rem;
				opacity: 10;
				color: $col-dark-faint;
				font-weight: 300;
				letter-spacing: 0.02rem;
			}
		}
		.items {
			// border: 1px solid beige;
			display: block;
			// min-width: 300px;

			.item {
				display:grid;
				line-height: 1.7rem;
				margin-block: 0.1rem;
				.subtitle {
					margin-top: -0.2em;
					color: $col-dark-fainter;
					font-size: 1rem;
					line-height: 1.2rem;
				}
				.titleNote {
					padding-inline-start:0.3rem;
					font-style: italic;
					color: $col-dark-fainter;
					font-size: 0.95rem;
					// margin-top: 0.3rem;
				}
			}
		}


	}

	@include bp(s-down) {
		.group {
			.header {
				h3 {
					font-size: 1rem;
				}
			}
			.items {
				.item {
					.subtitle {
						margin-top:0;
						font-size: inherit;
					}
				}
			}
		}
	}

}